import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Showdown from 'showdown'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation} from 'mdbreact'
import Layout from '../components/layout.js'
import SEO from '../components/seo.js'
import Hero from '../components/heroSubpage.js'
import Cta from '../components/cta.js'
import Card from '../components/card.js'
import SolutionBulletsVertical from '../components/solutionBulletsVertical.js'
import SolutionBulletsHorizontal from '../components/solutionBulletsHorizontal.js'
import SolutionText from '../components/solutionText.js'
import SolutionCardSet from '../components/solutionCardSet.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const SolutionsTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()  

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src }
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol lg="12">

              <div
                className={`font-w-400 text-xs-extra-large divlink ${post.html ? 'text-opening-para' : 'text-opening-para-mb0'}`}
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }}
              />

            {post.html && (
              <div
                className="richtext divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

    {post.frontmatter.section && 
    <>
      {post.frontmatter.section.map((sections, index) => (
        <Fragment key={index}>
          {sections.type === 'bullets vertical' && (
            <SolutionBulletsVertical data={sections} sectionClass={sections.class} />
          )}
          {sections.type === 'text' && (
            <SolutionText data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
          )}
          {sections.type === 'bullets horizontal' && (
            <SolutionBulletsHorizontal data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
          )}        
          {sections.type === 'card set' && (
            <>
            <SolutionCardSet data={sections} sectionClass={sections.class} discontinued={false} height="11.5rem" />
            {console.log(post.frontmatter.title.indexOf("56K"))}
            </>
          )}
          {sections.type === 'card set' && sections.feature.filter(feature => feature.discontinued == true).length > 0 && (
            <SolutionCardSet data={sections} sectionClass={sections.class} discontinued={true} height="11.5rem" />
          )}
        </Fragment>
      ))}
      </>
    }

      {post.frontmatter.footnote && (
          <section className="bg-white mt-n5">
            <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <hr className="md-line" />
                  </MDBCol>
                </MDBRow>
              <MDBRow>
                <MDBCol>
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-5 title-xs-medium title-large"> Footnotes </h2>
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.footnote), }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        )}    

      {post.frontmatter.helpfulresources && 
        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left pb-5">
                  {post.frontmatter.helpfulresources.title}
                  <span id="helpful-resources" className="anchor"> &nbsp; </span>
                </h2>

                {post.frontmatter.helpfulresources.subtitle && (
                  <p className="text-medium"> {post.frontmatter.helpfulresources.subtitle} </p>
                )}


              {post.frontmatter.helpfulresources.helpfulres && 
                <MDBRow className="pt-5">
                  {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => (
                    <Card
                      key={index}
                      collg="4"
                      colmd="6"
                      height="11rem"
                      title={helpfulres.title}
                      subtitle={helpfulres.subtitle}
                      description={helpfulres.description}
                      image={ helpfulres.image.childImageSharp.gatsbyImageData }
                      alttext={helpfulres.alttext}
                      placement={helpfulres.placement}
                      link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                      titleclass="title-small"
                      descriptionClass="text-card-small py-2"
                    />
                  ))}
                </MDBRow>
              }
            </MDBContainer>
          </MDBAnimation>
        </section>
      }

      {post.frontmatter.cta && 
        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      }
    </Layout>
  )
}
export default SolutionsTemplate

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "producthub" } }
    ) {
      fields {
        slug
      }
      html
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        ctatext
        ctaslug         
        openingpara
        footnote
        date(formatString: "MMMM DD, YYYY")
        alttext
        image {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90)
          }
        }   
        section {
          title
          subtitle
          description
          class
          type
          placement
          document {
            publicURL
          }
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          feature {
            title
            subtitle
            icon
            description
            discontinued
            sequence
            link
            linktext
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
        helpfulresources {
          title
          subtitle
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
    }
  } 
`